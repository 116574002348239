.App {
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1 {
  color: #dac9f4
}

h3 {
  color: #dac9f4
}

h4 {
  color: #dac9f4
}
