.flex-row {
	justify-content: space-between;
}

p {
	margin-left: 10%;
	margin-right: 10%;
}

h4 {
	margin-left: 10%;
	margin-right: 10%;
}

.Response {
	text-align: left;
	color: #dac9f4;
}