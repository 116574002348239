p {
	margin-left: 10%;
	margin-right: 10%;
	text-align: left;
}

h4 {
	margin-left: 10%;
	margin-right: 10%;
	text-align: left;
}

footer {
	margin-left: 10%;
	margin-right: 10%;
	text-align: left;
}